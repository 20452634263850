/**
 * jQuery mCycle 20200104
 *
 * Based on tCycle 20131130
 *
 * @author Kiril Reznik
 * @license MIT/GPL
 */
(function ($) {
  /**
   * MCycle
   */
  class MCycle {
    /**
     * Constructor
     *
     * @param {object} container - The domElement of the container.
     * @param {object} userOptions - The user options object.
     */
    constructor(container, userOptions) {
      this.$emmiter = $('body');

      this.$container = $(container);
      this.$slides = this.$container.children();

      this.options = $.extend(
        {
          fx: 'fadeOutIn',
          speed: 375,
          timeout: 4500, // 375 * 12
          touch: false,
          pager: '',
        },
        userOptions,
        this.$container.data(),
      );

      this.css = {
        container: {
          position: 'relative',
          overflow: 'hidden',
        },
        firstSlide: {
          visibility: 'hidden',
        },
        slides: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        },
      };

      this.timeoutId = 0;
      this.lock = false;

      this.direction = '';
      this.current = 0;
      this.next = 0;

      this.$pager = $(this.options.pager);

      // initialize
      this.initialize();
    }

    /**
     * Initialize
     */
    initialize() {
      // make sure that the slide with the most text is the first slide
      // ensures that the container height will be optimal
      let $firstSlide = this.$slides.first();
      let c = 0;
      this.$slides.each(function (index, slide) {
        const $slide = $(slide);
        const slideLength = $slide.text().length;

        if (slideLength > c) {
          c = slideLength;
          $firstSlide = $slide;
        }
      });

      // add the first slide as a placeholder for the box model
      // set container css
      this.$container.prepend($firstSlide.clone().css(this.css.firstSlide)).css(this.css.container);

      // hide all the slides, apply css, show the first slide
      this.$slides.hide().css(this.css.slides).first().show();

      this.options.timeout = this.randomTimeout(this.options.timeout);

      if (this.options.touch) {
        this.initializeTouch();
      }

      if (this.$pager.length) {
        this.initializePager();
      }

      this.timeoutId = setTimeout(this.transition.bind(this), this.options.timeout);
    }

    /**
     * Transition
     *
     * @param {number} [slideNumber] - The slide number to transition to.
     */
    transition(slideNumber) {
      // container is not visible
      if (!this.$container.is(':visible')) {
        this.scheduleTransition();
      }

      // set next
      if (typeof slideNumber === 'number') {
        if (slideNumber === -1) {
          this.next = this.mod(this.current - 1, this.$slides.length);
        } else {
          this.next = this.mod(slideNumber, this.$slides.length);
        }
      } else {
        this.next = this.mod(this.current + 1, this.$slides.length);
      }

      // set direction
      if (this.next > this.current) {
        this.direction = 'forward';
      } else {
        this.direction = 'reverse';
      }

      // finish currently running animations
      this.$slides.velocity('finish', true);

      // set slide objects
      const $currentSlide = $(this.$slides[this.current]);
      const $nextSlide = $(this.$slides[this.next]);

      // perform effects
      this.lock = true;

      switch (this.options.fx) {
        case 'fadeOutIn':
          $currentSlide.velocity('fadeOut', this.options.speed);
          $nextSlide.velocity('fadeIn', this.options.speed);
          this.lock = false;
          this.scheduleTransition();
          break;

        case 'slideOutThenIn':
          let firstEffect = 'transition.slideLeftOut';
          let secondEffect = 'transition.slideRightIn';

          if (this.direction === 'reverse') {
            firstEffect = 'transition.slideRightOut';
            secondEffect = 'transition.slideLeftIn';
          }

          $currentSlide.velocity(firstEffect, this.options.speed * 0.5, () => {
            $nextSlide.velocity(secondEffect, this.options.speed, () => {
              this.lock = false;
              this.scheduleTransition();
            });
          });
          break;
      }

      this.current = this.next;
      this.$emmiter.trigger('transitionComplete.mcycle');
    }

    /**
     * Schedule Transition
     */
    scheduleTransition() {
      // clear timeout
      clearTimeout(this.timeoutId);

      // set a new timeout
      this.timeoutId = setTimeout(this.transition.bind(this), this.options.timeout);
    }

    /**
     * Initialize Touch
     */
    initializeTouch() {
      $.Finger.motionThreshold = 25;
      this.$container.on('drag', (e) => {
        // fire only when the motion is horizontal
        if (e.orientation === 'horizontal') {
          e.stopPropagation();

          if (!this.lock) {
            // left to right, previous slide
            if (e.direction === 1 && e.end === true) {
              this.transition(-1);
            }
            // right to left, next slide
            if (e.direction === -1 && e.end === true) {
              this.transition();
            }
          }
        }
      });
    }

    /**
     * Initialize Pager
     */
    initializePager() {
      const list = [];

      this.$slides.each((index) => {
        const $item = $('<span>&bull;</span>');
        $item.css({
          userSelect: 'none',
        });
        $item.on('click', (e) => {
          if (!this.lock) {
            $(e.currentTarget).addClass('active').siblings().removeClass('active');
            this.transition(index);
          }
          return false;
        });
        list.push($item);
      });

      list[0].addClass('active');
      this.$pager.prepend(list);

      // update pager
      this.$emmiter.on('transitionComplete.mcycle', () => {
        this.$pager.children().removeClass('active').eq(this.current).addClass('active');
      });
    }

    /**
     * Random Timeout
     *
     * @param {number|string} timeout
     *
     * @return {number}
     */
    randomTimeout(timeout) {
      if (typeof timeout === 'string') {
        const timeoutOptions = timeout.split('-');
        const timeoutMin = Math.ceil(timeoutOptions[1] || 5000);
        const timeoutMax = Math.floor(timeoutOptions[2] || 10000);

        timeout = Math.floor(Math.random() * (timeoutMax - timeoutMin + 1)) + timeoutMin;
      }
      return timeout;
    }

    /**
     * Modulo with the result having the Divisor's sign
     *
     * @param {number} n - Dividend.
     * @param {number} m - Divisor.
     *
     * @return {number}
     */
    mod(n, m) {
      return ((n % m) + m) % m;
    }
  }

  $.fn.mcycle = function (userOptions = {}) {
    return this.each(function () {
      new MCycle(this, userOptions);
    });
  };

  // jQuery 3+
  $(window).on('load', function () {
    $('.mcycle').mcycle();
  }); // load
})(jQuery);
